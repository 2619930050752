import { For, Show, type Component } from 'solid-js';
import { IconAlert, IconCircleCheck, IconVolume, IconX } from '~/components/common/icons';
import { type ToastType, type Toast, remove, toasts } from './store';

const configMap: Record<ToastType, { icon: Component<{ class?: string }>; color: string }> = {
  normal: { icon: IconVolume, color: 'text-blue' },
  success: { icon: IconCircleCheck, color: 'text-[#1e967d]' },
  error: { icon: IconAlert, color: 'text-error' },
};

const Toast: Component<Toast> = (props) => {
  // eslint-disable-next-line solid/reactivity
  const config = configMap[props.type];

  return (
    <li class={`animate-toast-in relative flex gap-2 overflow-hidden rounded-xl bg-white p-4 text-sm shadow-2xl ${config.color}`}>
      <config.icon class="size-5 opacity-90" />
      <div class="flex-1">
        <Show when={props.title != null}>
          <h4 class="mb-1 font-medium">{props.title}</h4>
        </Show>
        <p class="text-sm">{props.message}</p>
      </div>
      <button type="button" class="p-0.5 text-gray-500 transition-colors hover:text-gray-800" onClick={() => remove(props.id)}>
        <IconX class="size-4" />
      </button>
      {/* <i class="absolute bottom-0 left-0 h-0.5 animate-progress bg-current" style={{ 'animation-duration': `${props.timeout}ms` }} /> */}
    </li>
  );
};

export const Toaster: Component = () => (
  <Show when={toasts.length}>
    <ol class="fixed inset-x-0 top-0 z-50 m-10 mx-auto flex w-96 flex-col-reverse gap-4">
      <For each={toasts}>{(toast) => Toast(toast)}</For>
    </ol>
  </Show>
);
