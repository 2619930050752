import { Link, Meta } from '@solidjs/meta';
import { useStyling } from '~/global-contexts/CompanyStyling';

export const CustomMeta = () => {
  const { companyStyling } = useStyling();

  return (
    <>
      <Link rel="icon" href={`/api${companyStyling?.()?.iconUrl}`} />
      <Meta name="theme-color" content={companyStyling?.()?.metadataThemeColor} />
    </>
  );
};
