import { createUniqueId } from 'solid-js';
import { createStore } from 'solid-js/store';

type ToastType = 'normal' | 'success' | 'error';

interface ToastOptions {
  title?: string;
  message: string;
  type?: ToastType;
  timeout?: number;
}

interface Toast {
  id: string;
  title?: string;
  message: string;
  type: ToastType;
  timeout: number;
}

interface ToastFn {
  (message: string, type?: ToastType): void;
  (options: ToastOptions): void;
}

type ToastTypeFn = (message: string, options?: Omit<ToastOptions, 'message' | 'type'>) => void;

type ToastWrap = ToastFn & { [K in ToastType]: ToastTypeFn };

const [toasts, setToasts] = createStore<Toast[]>([]);

const remove = (id: string) => setToasts((prev) => prev.filter((i) => i.id !== id));

const toastFn: ToastFn = (...args) => {
  const [message, type] = args;
  const item = {
    id: createUniqueId(),
    type: typeof type === 'string' ? (type as ToastType) : 'normal',
    timeout: 3000,
    ...(typeof message === 'string' ? { message: message } : message),
  } as Toast;
  setToasts((prev) => [...prev, item].slice(-3));
  setTimeout(() => remove(item.id), item.timeout);
};

const toast = new Proxy(toastFn, {
  get: (_, type: ToastType): ToastTypeFn => {
    return (message, options) => toastFn({ message, type, ...options });
  },
}) as ToastWrap;

export { toasts, toast, remove };
export type { ToastOptions, Toast, ToastWrap, ToastType };
