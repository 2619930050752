// @refresh reload
import './app.css';
import { MetaProvider } from '@solidjs/meta';
import { Router } from '@solidjs/router';
import { clientOnly } from '@solidjs/start';
import { FileRoutes } from '@solidjs/start/router';
import { Suspense } from 'solid-js';
import { CustomMeta } from '~/components/common/Meta';
import { ImagePreview } from './components/common/ImagePreview';
import { Toaster } from './components/common/toaster/Toaster';
import Navigator from './framework/Navigator';
import { CompanyStyling } from './global-contexts/CompanyStyling';
import { ErrorHandler } from './global-contexts/ErrorHandler';

const HomeLayout = clientOnly(() => import('./components/layouts/HomeLayout'));

export default function Root() {
  return (
    <MetaProvider>
      <Router
        root={(props) => (
          <Suspense>
            <CompanyStyling>
              <ErrorHandler>
                <Navigator />
                <CustomMeta />
                <HomeLayout>{props.children}</HomeLayout>
                <ImagePreview />
                <Toaster />
              </ErrorHandler>
            </CompanyStyling>
          </Suspense>
        )}>
        <FileRoutes />
      </Router>
    </MetaProvider>
  );
}
